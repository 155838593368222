import { ButtonSubmit } from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import HandleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import topicListContext from '~/contexts/topicListContext';
import { isMemberGroupLead } from '~/helper/userRoles';
import flipBannerStyles from '~/styles/components/Banners/Banner.css';

import type { LinksFunction } from '@remix-run/node';
import type { RouteTyping } from 'types';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: flipBannerStyles }];

type Props = {
  type?: 'topic';
};

const StudentViewBanner = ({ type }: Props) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const group = (matches.find((m) => m.handle?.id === HandleIds.Group)?.data as RouteTyping<'Group'>)?.group?.data;
  const { studentView } = useContext(topicListContext);
  const isGroupLead = isMemberGroupLead(group);
  const showBanner = isGroupLead && studentView === 'true';
  const userPrefFetcher = useFetcher();

  const setToEducatorView = () => {
    userPrefFetcher.submit(
      { studentView: false, _action: 'UpdateStudentViewUserPreferences' },
      { action: resourceRoutes.userPreferences, method: 'post' }
    );
  };

  if (!showBanner) return null;

  return (
    <div className={classNames('canvas-column__full', { 'background__bg-2 mb32': type === 'topic' })} role="banner">
      <div className="flex-row-center">
        <p>
          <b>{t('studentViewBanner.textHere')}</b>
        </p>
        <ButtonSubmit
          className="ml-a"
          data-testid="studentViewBanner__button__educatorView"
          onClick={setToEducatorView}
          size="36"
          loading={userPrefFetcher.state !== 'idle'}>

          {t('studentViewBanner.educatorView')}
        </ButtonSubmit>
      </div>
    </div>);

};

export default StudentViewBanner;
import {
  Button,
  IcFluentArchive24Regular,
  IcFluentCopy24Filled,
  IcFluentCopy24Regular,
  IcFluentDismiss24Regular,
  IcFluentShareIos24Regular,
  ModalConsumer,
  Tooltip } from
'@flipgrid/flipkit';
import { useFetcher, useLoaderData, useParams, useSearchParams } from '@remix-run/react';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import GroupActionsDropdown, { links as groupActionsDropdownStyles } from './GroupActionsDropdown';
import StudentViewBanner from '../Banners/StudentViewBanner';
import ColeadInvitesWrapper, { links as coleadInvitesWrapperStyles } from '../Coleads/ColeadInvitesWrapper';
import ShareGroupModalBtn, { links as shareGroupModalBtnStyles } from '../Share/ShareGroupModalBtn';
import EngagementStats, { links as engagementStatsStyles } from '../Utility/components/EngagementStats';
import resourceRoutes from '~/constants/resourceRoutes';
import globalContext from '~/contexts/globalContext';
import topicListContext from '~/contexts/topicListContext';
import checkIsLtiGroup from '~/helper/checkIsLtiGroup';
import { isFlagPresent } from '~/helper/helper';
import { handleError } from '~/helper/imgOnError';
import { isMemberGroupLead } from '~/helper/userRoles';
import useGetUser from '~/hooks/useGetUser';
import useTooltipTrigger from '~/hooks/useTooltipTrigger';
import groupHeaderStyles from '~/styles/components/Groups/GroupHeader.css';

import type { LinksFunction } from '@remix-run/node';
import type { SyntheticEvent } from 'react';
import type { DisplayGroup, Group, RouteTyping } from 'types';

export const links: LinksFunction = () => [
...groupActionsDropdownStyles(),
...shareGroupModalBtnStyles(),
...engagementStatsStyles(),
...coleadInvitesWrapperStyles(),
{ rel: 'stylesheet', href: groupHeaderStyles }];


type Props = {
  entity: Group | DisplayGroup;
};

const GroupHeader = ({ entity }: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [wasCopied, setWasCopied] = useState(false);
  const { announceLiveMessage, breakPoints } = useContext(globalContext);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useTooltipTrigger(wasCopied, buttonRef);
  const { showBanner, setShowBanner, studentView } = useContext(topicListContext);
  const joinGroupFetcher = useFetcher();
  const user = useGetUser();
  const isLoggedOutGroup = entity && 'resource_type' in entity;
  const group =
  entity && 'resource_type' in entity && entity?.resource_type !== 'grid' ? entity.grid : (entity as Group);
  const coleadInvites = (useLoaderData() as RouteTyping<'Group'>)?.coleadInvites?.data;
  const loggedOutJoinCode = useParams()?.vanity;
  const joinCode = 'vanity_token' in group ? group.vanity_token : (loggedOutJoinCode as string);
  const isGroupLead = !isLoggedOutGroup && isMemberGroupLead((group as Group), studentView);
  const isGroupArchived = !isLoggedOutGroup && entity?.active === false;
  const accountUser = user?.accountType === 'account';
  const ephemeralUser = user?.accountType === 'ephemeral';
  const shouldDisplayShareModal = searchParams.get('new') === 'true';
  const showTooltip = wasCopied ? { show: wasCopied } : {}; // fixes issue where tooltip will not show on hover

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const displayBanner = useMemo(
    () =>
    !isDeprecated &&
    !isGroupArchived &&
    showBanner &&
    isGroupLead &&
    group &&
    group.member_count <= 1 &&
    !checkIsLtiGroup(group),
    [isGroupArchived, showBanner, isGroupLead, group, isDeprecated]
  );

  const activateGroup = () => {
    const formData = new FormData();
    formData.append('id', group?.id?.toString() || '');
    formData.append('_action', 'updateGroup');
    formData.append('active', 'true');
    formData.append('locked', 'false');
    joinGroupFetcher.submit(formData, { method: 'post', action: resourceRoutes.updateState });
  };

  const copy = () => {
    navigator.clipboard.writeText(joinCode);
    announceLiveMessage(t('shared.copiedJoinCode'));
    setWasCopied(true);
  };

  useEffect(() => {
    const resetCopyDelay = setTimeout(() => {
      setWasCopied(false);
    }, 925);
    return () => {
      clearTimeout(resetCopyDelay);
    };
  }, [wasCopied]);

  const dismissBanner = () => {
    setShowBanner((prev) => !prev);
  };

  return (
    <>
      <StudentViewBanner />
      <ModalConsumer>
        {({ showModal }) =>
        <div className="groupHeader__wrapper canvas-column__full">
            {!isDeprecated && coleadInvites &&
          <ColeadInvitesWrapper
            className="canvas-column__full coleadInvitesWrapper__groupHeader"
            coleadInvites={coleadInvites} />}


            {displayBanner &&
          <div className="groupHeader__membersBannerWrapper canvas-column__full">
                <section className="groupHeader__membersBanner">
                  <p className="groupHeader__membersBannerText">
                    <Trans i18nKey="groupHeader.addStudents">
                      Add students to your group by sharing the&nbsp;
                      <IcFluentShareIos24Regular width={20} height={20} className="iconFill__fg-1" />
                      &nbsp;
                      <ShareGroupModalBtn group={entity} showModal={showModal} type="text">
                        invite link
                      </ShareGroupModalBtn>
                      .
                    </Trans>
                  </p>
                  <Button
                aria-label={t('groupHeader.noMemberCloseButton')}
                className="groupHeader__membersBannerButton"
                theme="tertiary"
                size="26"
                icon={<IcFluentDismiss24Regular width={16} height={16} />}
                data-testid="groupHeader__button__dismissBanner"
                onClick={dismissBanner} />

                </section>
              </div>}

            {isDeprecated ?
          null :
          isGroupArchived &&
          <div className="groupHeader__archivedBannerWrapper canvas-column__full">
                    <section className="groupHeader__archivedBanner">
                      <>
                        <IcFluentArchive24Regular fill="var(--fk-color__foreground-1)" />
                        {t('groupHeader.archivedBannerText')}
                        <Button
                  theme="secondary"
                  variant="block"
                  size="36"
                  data-testid="groupHeader__button__archiveGroup"
                  onClick={activateGroup}>

                          {t('shared.activateGroup')}
                        </Button>
                      </>
                    </section>
                  </div>}

            <div
            className={classNames(
              'groupHeader',
              { 'canvas-column__full': accountUser },
              { 'canvas-column__header': ephemeralUser },
              { '-ephemeral': ephemeralUser }
            )}>

              <img
              className="fullWidthBackgroundImage fullLayout"
              src={`${group.image_url}?size=medium`}
              alt={t('shared.groupCover')}
              onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)}
              loading="lazy" />


              <Tooltip label={wasCopied ? t('common.copied') : t('common.copy')} {...showTooltip}>
                <Button
                ref={buttonRef}
                theme="overlay"
                size="36"
                onClick={copy}
                iconRight={wasCopied ? <IcFluentCopy24Filled /> : <IcFluentCopy24Regular />}
                data-testid="groupHeader__button__joinCode"
                className="groupHeader__joinCode">

                  {t('shared.joinCode', { joinCode })}
                </Button>
              </Tooltip>

              <div className="groupHeader__imageContent">
                <h1 className="groupHeader__title">{group.name}</h1>
                <div className="groupHeader__imageContentRow">
                  <EngagementStats group={entity} />
                  <div className="groupHeader__actions">
                    {!isDeprecated &&
                  <ShareGroupModalBtn
                    showText={breakPoints.isScreenMedium}
                    group={entity}
                    showModal={showModal}
                    loadShareModalOnInit={shouldDisplayShareModal} />}


                    <GroupActionsDropdown group={entity} variant="header" />
                  </div>
                </div>
              </div>
            </div>
          </div>}

      </ModalConsumer>
    </>);

};

export default GroupHeader;